import React from "react";
import Form from "./Form";

const Renew = () => {
  return (
    <div>
      <Form />
    </div>
  );
};

export default Renew;
