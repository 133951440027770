import React from "react";

const Bcard = () => {
  return (
    <div>
      <section className="text-gray-600 body-font bg-[#FFFFFF]">
        <div className="container px-3 py-[50px] mx-auto">
          <div className="flex flex-wrap -m-1">
            <div className="p-4 md:w-1/3">
              <div className="p-5">
                <h5 className="tracking-widest text-1xl title-font font-medium text-blue-600 mb-1">
                  Caresure
                </h5>
                <h1 className="title-font text-4xl font-medium text-gray-900 mb-3">
                  A 350&#176; System
                </h1>
                <p className="leading-relaxed mb-3 font-medium">
                  Health is not a destination but a journey. Enrich your health
                  and well-being with our wellness services.
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  className="lg:h-48 md:h-36 w-full object-cover object-center"
                  src="https://d28c6jni2fmamz.cloudfront.net/Test_6b932c0bf6.svg"
                  alt="blog"
                />
                <div className="p-6">
                  <h1 className="title-font text-2xl font-medium text-gray-900 mb-3">
                    Talk to Caresure
                  </h1>
                  <p className="leading-relaxed mb-3 font-medium">
                    Free, expert telemedicine facility
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <button className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                      Learn More
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14" />
                        <path d="M12 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  className="lg:h-48 md:h-36 w-full object-cover object-center"
                  src="https://d28c6jni2fmamz.cloudfront.net/Covid_19_3ce2ac46b4.svg"
                  alt="blog"
                />
                <div className="p-6">
                  <h1 className="title-font text-2xl font-medium text-gray-900 mb-3">
                    COVID-19 Helpline
                  </h1>
                  <p className="leading-relaxed font-medium mb-3">
                    COVID advisory & support
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <button className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                      Learn More
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14" />
                        <path d="M12 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  className="lg:h-48 md:h-36 w-full object-cover object-center"
                  src="https://d28c6jni2fmamz.cloudfront.net/Health_Library_bbacb539c4.svg"
                  alt="blog"
                />
                <div className="p-6">
                  <h1 className="title-font text-2xl font-medium text-gray-900 mb-3">
                    Health Library
                  </h1>
                  <p className="leading-relaxed mb-3 font-medium">
                    Blogs from our experts
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <button className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                      Learn More
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14" />
                        <path d="M12 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  className="lg:h-48 md:h-36 w-full object-cover object-center"
                  src="https://d28c6jni2fmamz.cloudfront.net/OPD_services_7973dc9c63.svg"
                  alt="blog"
                />
                <div className="p-6">
                  <h1 className="title-font text-2xl font-medium text-gray-900 mb-3">
                    Outpatient Portal
                  </h1>
                  <p className="leading-relaxed mb-3 font-medium">
                    Book your consultation online
                  </p>
                  <div className="flex items-center flex-wrap">
                    <button className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                      Learn More
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14" />
                        <path d="M12 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  className="lg:h-48 md:h-36 w-full object-cover object-center"
                  src="https://d28c6jni2fmamz.cloudfront.net/Wellness_7dff2b4c84.svg"
                  alt="blog"
                />
                <div className="p-6">
                  <h1 className="title-font text-2xl font-medium text-gray-900 mb-3">
                    Wellness Program
                  </h1>
                  <p className="leading-relaxed mb-3 font-medium">
                    Start your healthy lifestyle
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <button className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                      Learn More
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14" />
                        <path d="M12 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Bcard;
