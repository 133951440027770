import React from "react";
import ClaimTabs from "./ClaimTabs";
import Header from "../Home/Header.js";
const Claims = () => {
  return (
    <div> 
      <Header/>
     <ClaimTabs />
    </div>
  );
};

export default Claims;
